@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,200,300,400,500,600,700|Montserrat|Open+Sans|Roboto&display=swap');
@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/core/lib/scss/variables';
@import 'global/styles';
// html {
//   box-sizing: border-box;
// }

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  font-family: $font-family;
}

@media only screen and (max-width: 768px) {
  .bp3-card {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .bp3-form-group.bp3-inline label.bp3-label {
    min-width: 28%;
  }
}

body > div.bp3-portal > div > div > div > div.bp3-popover-content > div > div > input{
  width:100% !important;
}