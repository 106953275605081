$font-family: 'IBM Plex Sans', sans-serif;
$background-color: #f5f8fa;
$dark-background-color: #30404d;
$main-color: #773be2;

@mixin for-tablet-and-less {
  @media screen and (max-width: 900px) {
    @content;
  }
}

.bp3-tab-list {
  flex-wrap: wrap;
}

.bp3-label {
  min-width: 25%;
}

.bp3-slider {
  min-width: 200px !important;
}

.bp3-dark svg g g text {
  fill: #f5f8fa !important;
}

.hidden {
  display: none;
}
